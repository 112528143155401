.admin-preview {
  .single-blog {
    margin: 80px 15%;
    max-width: 1200px;
    min-width: 350px;

    .blog-image {
      border-radius: 20px;
    }

  }

  /* Assuming a class name of "quill-editor" for the React Quill component */
  .ql-container.ql-disabled {
    border: none;
  }

  /* Optionally, you can remove the outline as well */
  .ql-container.ql-disabled .ql-editor {
    outline: none;
  }

  .leave-comment {
    &::placeholder {
      padding: 2px;
    }

    padding: 10px;
  }
}

.admin-blog-page {
  overflow: hidden;

  .blog-header {
    background-color: var(--primary-blue-color);
    border-radius: 8px;

    h1 {
      color: #fff;
    }

    .add-blog-btn {
      background-color: var(--primary-orange-color);
      padding: 5px 10px;
      border-radius: 8px;
    }
  }

  .blog-content {
    padding: 0 16px 30px;
    display: -webkit-box;
    width: 100%;
    overflow-x: auto;
  }

  .btn-pagination {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    margin-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:disabled {
      opacity: 0.6;
    }
  }
}

.admin-add-blog {
  margin: 20px;
  overflow: hidden;

  .blog-header {
    background-color: var(--primary-blue-color);
    border-radius: 8px;
    color: #fff;
  }

  .ql-container {
    height: 300px;
  }
}

.blog-card-wrapper,
.admin.blog-card {
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
}

.blog-card {
  width: 300px;
  margin-right: 12px;
  margin-bottom: 16px;
  flex: 0 1 calc(25% - 1em);

  .blog-desc {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}


.head-details {
  border-bottom: none;
  border-bottom: 1px solid #ccc;
}

.hyphen {
  display: inline-block;
  width: 2em;
  height: 3px;
  background-color: #ccc;
}

.comment-sect {
  margin-top: 10px;

  .element {
    display: flex;
    align-items: center;
  }

  .left-bar,
  .right-bar {
    width: 100%;
    height: 1px;
    background-color: #ccc;
  }

  .text {
    width: 100%;
    max-width: 170px;
    flex-grow: 1;
    text-align: center;
    padding: 0 10px;
    color: #333;
  }
}

.other-blogs-sect {
  .other-blogs {
    display: inline-flex;

    .other-post {
      margin: 20px;

      .blog-desc {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .post-img {
        width: 250px;
        height: 180px;
      }
    }
  }
}
