
.blog-post {
  animation: slide-up 1s ease;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-fill-mode: both;
  opacity: 0;
  .title {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .inner-blog {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 8px;
    min-width: 400px;
    .post-img {
      width: 100%;
      height: 300px;
      transition: transform 0.3s ease;
      &:hover {
        transform: scale(1.05);
      }
    }
    .blog-stats {
      .icon-wrapper {
        display: flex;
        justify-content: flex-start;
      }
      .icon { 
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      }
    }
    .blog-desc {
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}