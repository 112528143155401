.users-roles-page {
  .table-item-wrapper {
    display: inline-flex;
    flex-wrap: wrap;
    div {
      margin: 2px;
      height: 100%;
    }
  }
  .page-nav {
    // display: flex;
    // justify-content: center;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    padding: 8px;
    border: 1px solid var(--primary-blue-color);
  }

  .page-nav-num {
    width: 33px;
    height: 33px;
    border-radius: 50%;
  }
}