@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Gotham';
  src: url('./Assets/fonts/GothamOffice-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bison';
  src: url('./Assets/fonts/Bison-Bold.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}


body {
  margin: 0;
  font-family: 'Gotham', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'Gotham', sans-serif;
}

:root {
  --primary-color: #FC8A2B;
  --primary-orange-color: #e14d2a;
  --primary-blue-color: #001253;
  --secondary-blue-color: #3e6d9c;
}

.column {
  display: flex;
  flex-direction: column;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.text-hover:hover {
  color: var(--primary-color);
}

h1 {
  font-size: 28px;
  font-weight: 700;
}

.bg-primary-blue-color {
  background-color: var(--primary-blue-color);
}

.primary-color {
  color: var(--primary-color);
}

.profile-save {
  background-color: var(--primary-blue-color);
  border-radius: 5px;
  color: #fff;
}

.nav-tabs {
  border-bottom: none;

  .nav-link {
    border: none;
    color: #000;

    &.active {
      border-bottom: 3px solid var(--primary-color) !important;
    }
  }
}

.nav-tabs {
  padding: 0 10px;
}

.tab-content {
  background-color: #eeeeee;
  padding: 10px;
}

div[role="tabpanel"] {
  height: 100%;
}

.form-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  input, textarea, select {
    border: 1px solid #eee;
    min-width: 300px;
    padding: 5px;
    border-radius: 4px;
    font-size: 15px;
  }
}

.form-field.half {
  margin-right: 10px;

  input {
    width: 200px;
    min-width: 200px;
  }

  &:last-child {
    margin-right: 0;
  }
}


.page-nav {
  // display: flex;
  // justify-content: center;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  padding: 8px;
  border: 1px solid var(--primary-blue-color);
}

.page-nav-num {
  width: 33px;
  height: 33px;
  border-radius: 50%;
}
