.sidebar-container {
  width: 16%;
  min-width: 265px;
  z-index: 99;
}
.sidebar {
  border-right: 1px solid #E5EBEE;
  width: 16rem;
  .nav-item {
    font-size: 14px;
    font-weight: 700;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    color: #2A3547;
    cursor: pointer;

    &.selected {
      color: #fff;
      background-color: var(--primary-color);
    }

    &:has(~ .nav-item:hover) {
      background-color: none;
    }

    &:hover ~ .nav-item {
      background-color: none;
    }

    &.minimized {
      padding: 0;
      padding: 5px 0 5px 8px;
    }

  }
  .nav-item:hover {
    // background-color: var(--secondary-blue-color);
    background-color: #FDBF8D;
    color: #fff;
  }
  &.minimized {
    width: 70px;
    .nav-item-wrapper {
      &:hover {
        background-color: #FDBF8D!important;
        color: #fff!important;
        svg {
          fill: #fff;
        }
      }
    }
  }
}


// @media (max-width: 1203px) {
//   .sidebar {
//     width: 50px;
//   }
//   .sidebar-container {
//     width: 50px;
//     min-width: 0;
//   }
//   .admin-blog-page {
//     width: 90%;
//   }
// }