.poll-card {
  // flex-grow: 1;
  // display: flex;
  .progress {
    width: 100%;
    height: 45px;
    border-radius: 30px;
    background-color: var(--primary-blue-color);
    margin-bottom: 10px;
  }

  .blog-stats {
    .icon-wrapper {
      display: flex;
      justify-content: flex-start;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    }
  }
}