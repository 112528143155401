.content-creator-bg {
  .profile-pic-cover {
    border: 10px solid var(--primary-blue-color);
    border-radius: 50%;
  }
  .sidebar-container {
    width: 16%;
    min-width: 265px;
    z-index: 99;
  }
  .sidebar {
    border-right: 1px solid #E5EBEE;
    width: 18rem;
    .nav-item {
      font-size: 14px;
      font-weight: 700;
      border-radius: 5px;
      padding: 10px 10px;
      display: flex;
      color: #fff;
      cursor: pointer;
  
      &.selected {
        color: var(--primary-blue-color);
        background-color: #fff;
      }
  
      &:has(~ .nav-item:hover) {
        background-color: none;
      }
  
      &:hover ~ .nav-item {
        background-color: none;
      }
  
    }
    .nav-item:hover {
      background-color: #fff;
      color: var(--primary-blue-color);
    }
  }
}


// @media (max-width: 1203px) {
//   .sidebar {
//     width: 50px;
//   }
//   .sidebar-container {
//     width: 50px;
//     min-width: 0;
//   }
//   .admin-blog-page {
//     width: 90%;
//   }
// }