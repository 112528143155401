.manage-forum {
  .title {
    font-size: 20px;
  }

  .description {
    font-size: 16px;
  }

  .tab-content {
    min-height: 68vh;
  }

  div[role="tabpanel"] {
    min-height: 60vh;
  }
}


.admin-sect.chat-card {
  width: 180px;
  min-height: 0 !important;

  .dropdown-toggle:hover, .dropdown-toggle {
    background: #fff;
    padding: 0;
  }

  .dropdown-menu {
    top: -10px !important;
  }
}

.forum-poll.poll-card {
  max-width: 300px;
}
