.right-sidebar {
  z-index: 100;
  width: 430px;

  .close-btn {
    padding: 5px;
    border-radius: 50%;
  }

  .user-details {
    span {
      color: #000000;
    }

    h3 {
      color: #000000;
      font-weight: 700;
    }
  }
}

.screen-overlay {
  z-index: 100;
}