.forum-card {
  box-shadow: rgba(100, 100, 111, 0.2) 0 3px 11px 0;
  margin: 20px;
  flex-grow: 1;
  max-width: 330px;
  cursor: pointer;

  .author {
    font-size: 13px;
  }

  .title {
    font-size: 15px;
  }

  .forum-desc {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14px;
  }

  .dropdown-toggle {
    &:hover, &::selection, &.show, &:active {
      background-color: #fff !important;
    }
  }

  .dropdown-toggle::after {
    content: none;
  }
}