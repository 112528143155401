.creator-blogs {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 10px;
}

.see-more-btn {
  background-color: var(--primary-blue-color);
  padding: 8px 10px;
  color: #fff;
  border-radius: 5px;
  position: relative;
  bottom: 0;
  margin-top: 30px;
}

.link-input {
  border: none;
  outline: none;
  padding: 5px;
  width: 70%;
  border-radius: 5px;
}
.toggle {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.toggle-checkbox {
  display: none;
}

.toggle-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-checkbox:checked + .toggle-label {
  background-color: var(--primary-blue-color); 
}

.toggle-label::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.toggle-checkbox:checked + .toggle-label::before {
  transform: translateX(20px);
}

.creator {
  .text-editor {
    background-color: #fff;
  }
}