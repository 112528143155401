.forum-tags {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;

  span {
    margin-right: 10px;
    font-size: 10px;
    border-radius: 20px;
    padding: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}