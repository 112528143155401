.forums {
  display: flex;
  justify-content: center;

  .feed-container {
    //max-width: 500px;
    width: 100%;
    margin: 0 10%;
    //padding: 0 10%;
    .sidebar-menu {
      position: relative;
      top: 0;
      left: 0;

      li {
        padding: 10px;
        text-transform: uppercase;
        width: 100px;
        font-weight: 700;
        font-size: 14px;
        cursor: pointer;

        &.selected {
          color: #fff;
          background-color: var(--primary-color);
        }

        &:nth-child(1) {
          margin-bottom: 10px;
        }
      }
    }

    main {
      width: 100%;
    }
  }

  .feeds-image {
    border-radius: 30px;
  }

  .feeds-content {
    .feeds-sidebar {
      width: 13%;

      li {
        font-size: 20px;
        padding: 10px 20px;
        cursor: pointer;

        &:hover {
          color: #fff;
          background-color: var(--primary-color);
        }
      }
    }

    .content {
      width: 73%;
      margin-left: 2%;
    }
  }
}
