.admin-forum-card {
  box-shadow: rgba(100, 100, 111, 0.2) 0 3px 11px 0;
  margin: 20px;
  //flex-grow: 1;
  min-width: 300px;
  width: 300px;
  //width: 500px;
  cursor: pointer;

  .author {
    font-size: 13px;
  }

  .title {
    font-size: 15px;
  }

  .forum-desc {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14px;
  }

  .dropdown-toggle {
    &:hover, &::selection, &.show, &:active {
      background-color: #fff !important;
    }
  }

  .dropdown-toggle::after {
    content: none;
  }

  .status {
    p {
      font-size: 12px;
    }
  }

  .forum-type {
    span {
      font-size: 13px;
      color: #3e6d9c;
    }
  }
}

.single-forum {
  .header-image {
    width: 100%;
    height: 37vh;
    object-fit: cover;
  }

  .text-section {
    display: flex;
    justify-content: space-between;

    .topic {
      font-weight: 700;
      font-size: 14px;
    }

    .forum-info {
      font-size: 13px;
    }

    .act-btn {
      padding: 6px 9px;
      text-transform: uppercase;
      color: #fff;
      font-size: 13px;
      background-color: var(--primary-color);
    }
  }

  .forum-desc {
    p {
      font-size: 14px;
    }
  }

  .react-tabs__tab-list {
    border-bottom: none;

    li {
      font-size: 13px;
    }

    .react-tabs__tab {
      border: none;
    }
  }
}


.single-forum {
  .tab-content {
    min-height: 40.5vh;
  }
}

.forum-chats-page {
  .add-chat-btn {
    background-color: var(--primary-color);
    padding: 8px;
    color: #fff;
    font-size: 12px;
    border-radius: 5px;
    width: 100%;
    max-width: 100px;
  }

  .chat-sect {
    display: flex;
    flex-wrap: wrap;

    .chat-card {
      cursor: pointer;
      height: 100px;
      box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
      margin: 10px;

      h3 {
        font-size: 15px;
        font-weight: 700;
      }

      .members-stats {
        p {
          font-size: 12px;
        }
      }
    }
  }

  .info-sect {
    min-width: 300px;

    .about {
      h3 {
        font-weight: 700;
        font-size: 16px;
      }

      p {
        font-size: 13px;
        margin-top: 15px;
      }
    }
  }
}


.single-chat {
  position: fixed;
  bottom: 0;
  right: 50px;
  height: 400px;
  min-width: 400px;
  padding-bottom: 70px;

  .chat-head {
    background-color: var(--secondary-blue-color);
    color: #fff;
    display: flex;
    padding: 10px;
    justify-content: space-between;

    h3 {
      font-size: 15px;
    }

    p {
      font-size: 12px;
      margin-top: 5px;
    }
  }

  .chat-content {
    background-color: #fff;
    padding: 10px;
    //margin-bottom: 5px;
    height: 100%;

    .chat-body {
      height: 275px;
      overflow-y: auto;

      .message-right {
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }

      .message-left {
        display: flex;
        justify-content: flex-start;
        width: 100%;
      }

      .message-body {
        display: flex;
        flex-direction: column;
        padding: 5px;
        border-radius: 5px;
        width: 50%;
        margin-bottom: 15px;

        h3 {
          font-size: 12px;
          font-weight: 700;
        }

        p {
          font-size: 12px;
        }

        .message-time {
          font-size: 10px;
          display: flex;
          justify-content: flex-end;
          margin-top: 3px;
          color: #B48B7D;
          font-weight: 700;
        }
      }

      .send-message {
        background-color: #AFC2D5;
      }

      .receive-message {
        background-color: #DFEFCA;
      }
    }
  }
}

.EmojiPickerReact {
  width: 100% !important;
  position: absolute !important;
  bottom: 72px !important;
  left: -50px;
}

.files-wrapper {
  img {
    margin-right: 20px;
  }
}

.chat-footer {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;

  input {
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 0 5px;
    padding: 5px;
    width: 100%;
    font-size: 13px;
  }
}

.attachment-modal {
  display: -webkit-box;
  width: 100%;
  overflow-x: auto;
}

.attachment-modal-emoji {
  .EmojiPickerReact {
    width: 350px !important;
    position: absolute !important;
    bottom: 320px !important;
    left: 300px;
    height: 350px !important;
  }
}

.eye-overlay {
  display: none;
}

.media_file:hover {
  .eye-overlay {
    display: flex;
    flex-direction: column;
  }
}

.discussion-emoji {
  .EmojiPickerReact {
    width: 350px !important;
    position: absolute !important;
    bottom: -100px !important;
    left: 13%;
    height: 350px !important;
  }
}
