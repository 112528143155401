.banner-img {
  background-image: url("../../Assets/images/banner-main.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  }

  .text {
    color: white;
    text-align: center;
    padding-top: 50%;
  }

}

.recent-articles {
  h1 {
    font-family: 'Bison', sans-serif;
  }

  .blogs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .blog-post {
      margin: 20px;
      max-width: 400px;
    }
  }

  .see-more-btn {
    background-color: var(--primary-orange-color);
    color: #fff;
    width: 300px;
    padding: 10px 0;
    border-radius: 0;
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.typing-animation {
  overflow: hidden;
  white-space: nowrap;
  animation: typing 2s steps(40, end);
  font-size: 2.3em;
}

.carousel-caption {
  min-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1240px) {
  .carousel-caption {
    min-width: 300px;

    p {
      font-size: 16px;
    }
  }
}

@media (max-width: 600px) {
  .carousel-caption {
    min-width: 300px;

    p {
      font-size: 12px;
    }
  }
}

.carousel-item-overlay {
  position: relative;
}

.carousel-item-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity (0.5) as desired */
}

.carousel-caption {
  /* Adjust the styles for the caption as needed */
  color: white;
}

.poll-progress {

}