.profile {
  .profile-card {
    border-radius: 15px;
    max-width: 400px;
  }
  input, textarea, button {
    border-radius: 5px;
  }
  .profile-save {
    background-color: var(--primary-blue-color);
    border-radius: 5px;
  }
  .mobile-input {
    margin-top: 10px;
  }
}
.modal-bg {
  background-color: rgba(0,0,0,0.6);
}