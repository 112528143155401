.sidebar {
  background-color: var(--primary-blue-color);
  min-height: 100vh;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .header-text {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
  }
  .logo-sect {
    margin-bottom: 30px;
    .logo {
      width: 80px;
      height: 80px;
    }
    .company-header {
      color: #fff;
      font-weight: 700;
      margin: 3px;
      font-size: 25px;
    }
  }
  .social-buttons {
    margin-top: 20px;
    width: 80%;
    .social-button {
      padding: 15px;
      border-radius: 8px;
      margin-bottom: 20px;
      text-align: center;
      .btn {
        color: #fff;
      }
      .btn-text {
        color: #fff;
        margin-left: 10px;
      }
    }
  }
}